import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import Img from "gatsby-image"

import Layout from '../components/Layout'
import BlogRoll from '../components/ArticlesRollIndex'
import PageHeading from "../components/PageHeading";

const Hero = props => <Img
    className="full-width-image margin-top-0"
    fluid={props.childImageSharp.fluid}
/>;

Hero.propTypes = {
    childImageSharp: PropTypes.any,
};

export const IndexPageTemplate = ({childImageSharp, title}) => (
    <>
        <Hero childImageSharp={childImageSharp}/>
        <PageHeading heading={title} className="title is-size-3 is-size-4-touch is-size-4-mobile"/>
        <section className="section section--gradient">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="content">
                            <BlogRoll/>
                            <div className="column is-12 has-text-centered">
                                <Link className="btn" to="/blog">
                                    Читать дальше
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)

IndexPageTemplate.propTypes = {
    childImageSharp: PropTypes.object,
    title: PropTypes.string,
}

const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark;

    return (
        <Layout
            title={frontmatter.pageTitle}
            description={frontmatter.description}
            keyWords={frontmatter.keyWords}
            ogImage={frontmatter.ogImage.childImageSharp.fixed.src}
        >

            <IndexPageTemplate
                childImageSharp={frontmatter.image.childImageSharp}
                title={frontmatter.title}
            />
        </Layout>
    )
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object
        })
    }),
}

export default IndexPage;

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                pageTitle
                keyWords
                ogImage {
                    childImageSharp {
                        fixed(width: 300, height: 300) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, maxHeight: 500, fit: COVER) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                description
            }
        }
    }
`;
