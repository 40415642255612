import React from 'react'
import PropTypes from 'prop-types';
import {graphql, StaticQuery} from 'gatsby';

import ArticleCard from "./ArticleCard";

class ArticlesRollIndex extends React.Component {
    render() {
        const {data} = this.props;
        const {edges: posts} = data.allMarkdownRemark;

        return (
            <div className="columns is-multiline articles-roll-index">
                {posts && posts.map(({node: post}) => <ArticleCard post={post} key={post.id}/>)}
            </div>
        );
    }
}

ArticlesRollIndex.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

export default () => (
    <StaticQuery
        query={graphql`
            query ArticlesRollIndexQuery {
                allMarkdownRemark(
                  limit: 9
                  sort: { order: DESC, fields: [frontmatter___date] }
                  filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 200, truncate: true)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                templateKey
                                date(formatString: "MMMM DD, YYYY")
                                featuredpost
                                featuredimage {
                                    childImageSharp {
                                        fluid(maxWidth: 340, maxHeight: 340, fit: COVER) {
                                          ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    `}
        render={(data, count) => <ArticlesRollIndex data={data} count={count}/>}
    />
);
